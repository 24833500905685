/* eslint-disable react/prop-types */
import React from 'react'
import { Navigate } from 'react-router-dom'
import './scss/style.scss'

export const PrivateRoute = ({ children }) => {
  const isAuthenticated = true
  if (isAuthenticated && localStorage.sessionId !== null) {
    return children
  }
  return <Navigate to="/login" />
}
